* {
    margin: 0;
    box-sizing: border-box;
    color: white;
}

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  font-family: "Outfit", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #cf5555;
  font-style: normal;
  background-color: #030b15;
  line-height: 1.75;
  overflow-x: hidden;
}

.landingpage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    transition: 0.5s;
    font-family: "sans-serif";
}

.video-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    object-fit: cover;
}

.bg-overlay {
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    z-index: 0;
}

.navbar {
    width: 100%;
    height: 6em;
    border-bottom: 2px solid white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
}

.menu {
    height: 3em;
    width: 4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 2em;
    cursor: pointer;
}

.menu div {
    width: 90%;
    height: 0.25em;
    background-color: white;
    border-radius: 1em;
}

.menu div:nth-of-type(2) {width: 40%;}

.home-text {
    width: 70%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 2;
}

h1 {
    font-size: 6em;
    animation-name: fade;
    animation-duration: 4s;
}

p {
    justify-self: flex-end;
    font-size: 2em;
}

.home-btn {
    width: 6em;
    height: 2em;
    border: 2px solid white;
    border-radius: 0.25em;
    background-color: rgba(255, 28, 28, 0.457);
    z-index: 1;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* ---------- Animation --------------- */
@keyframes fade {
    0% {
        opacity: 0;
        margin-left: 3em;
    }
    100% {
        opacity: 1;
    }
}